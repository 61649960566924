import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputMask from 'react-input-mask';

import style from './form.module.scss'
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import validator from "./validator";

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userNameValid: false,
            userNameHelperText: 'Use 2-128 character, , ex: John Smith',
            userName: '',

            userEmailValid: false,
            userEmailHelperText: 'Use 6-254 character, ex: abz@site.com',
            userEmail: '',

            userEmailRFCValid: false,
            userEmailRFCHelperText: 'Use 6-254 character, ex: abz@site.com',
            userEmailRFC: '',

            userIdValid: false,
            userIdHelperText: 'Use 2-128 character, ex: id_123_abzz_123',
            userId: '',

            userIdMASKValid: false,
            userIdMASKHelperText: 'Use 2-128 character, ex: id_123_abzz_123',
            userIdMASK: '',

            userPhoneMASKValid: false,
            userPhoneMASKHelperText: 'Ex: +38 (099) 999 - 99 - 99',
            userPhoneMASK: '',
            mask: '+38 (099) 999 - 99 - 99',


            userPhoneExtMASKValid: false,
            userPhoneExtMASKHelperText: 'Use 7-256 character, Ex: +44971234567',
            userPhoneExtMASK: '',

            userPinValid: false,
            userPinHelperText: 'Use 8 character, Ex: 1234-1234',
            userPin: '',

            userAboutValid: false,
            userAboutHelperText: 'Use 500 character',
            userAbout: '',

            setOpen: false,
            buttonDis: true,

        }
    }

    changeIdMaskHandler = (e) => {
        let n = e.target.value.replace(/[^a-z0-9_]+/g,'');
        if (n !== null) {
            this.setState({
                userIdMASK: n,
            })
        } else {
            if(this.state.userIdMASK.length === 1){
                this.setState({
                    userIdMASK: '',
                    userIdMASKHelperText: ''
                })
            } else {
                e.target.value = this.state.userIdMASK
            }
        }
    }

    paster = (e) => {
        var clipboardData, pastedData;

        e.stopPropagation();
        e.preventDefault();

        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');


        this.setState({
            userPhoneMASK: pastedData,
            mask: '+38 (099) 999 - 99 - 99',
        }, )
    }

    changePhoneExtMASKHandler = (e) => {
        let n = e.target.value.replace(/[^0-9+\s,;()]+/g,'');
        if (n !== null) {
            this.setState({
                userPhoneExtMASK: n,
            })
        } else {
            if(this.state.userIdMASK.length === 1){
                this.setState({
                    userPhoneExtMASK: '',
                    userPhoneExtMASKHelperText: ''
                })
            } else {
                e.target.value = this.state.userPhoneExtMASK
            }
        }
    }

    changeAllHandler = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    validate = (e) => {
        let valid = validator[e.target.name](this.state[e.target.name])

        this.setState({
            [`${e.target.name}Valid`]: !valid.isValid,
            [`${e.target.name}HelperText`]: valid.isWhy,
            [e.target.name]: valid.value
        })
        this.validateAllBlur()
    }

    validateAll = (e) => {
        const formElementsArr = ['userName', 'userEmail', 'userEmailRFC', 'userId', "userIdMASK", "userPhoneMASK", "userPhoneExtMASK", "userPin", "userAbout"];
        let applyCounter = formElementsArr.length;
        let counter = 0;

        formElementsArr.forEach((el) => {
            let valid = validator[el](this.state[el])
            if (valid.isValid) {
                counter ++;
                if (counter === applyCounter) {
                    this.handleClickOpen()
                }
            }
            this.setState({
                [`${el}Valid`]: !valid.isValid,
                [`${el}HelperText`]: valid.isWhy,
                [el]: valid.value
            })
        })
    }

    validateAllBlur = (e) => {
        const formElementsArr = ['userName', 'userEmail', 'userEmailRFC', 'userId', "userIdMASK", "userPhoneMASK", "userPhoneExtMASK", "userPin", "userAbout"];
        let applyCounter = formElementsArr.length;
        let counter = 0;

        formElementsArr.forEach((el) => {
            let valid = validator[el](this.state[el])
            if (valid.isValid) {
                counter ++;
                if (counter === applyCounter) {
                    this.setState({
                        buttonDis: false
                    })
                }
            } else {
                this.setState({
                    buttonDis: true
                })
            }
        })
    }

    handleClickOpen = () => {
        this.setState({
            setOpen: !this.state.setOpen,

            buttonDis: true
        })

        if(this.state.setOpen) {
            this.setState({
                userAbout: '',
                userName: '',
                userEmail: '',
                userEmailRFC: '',
                userId: '',
                userIdMASK: '',
                userPhoneMASK: '',
                userPhoneExtMASK: '',
                userPin: '',

            },)
        }
    };


    render() {
        return (
            <div className={style.formWrapper}>
                <Container maxWidth={'md'}>
                    <form autoComplete="on">
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField name="userName" label="Name" variant="outlined" fullWidth helperText={this.state.userNameHelperText} value={this.state.userName} onBlur={this.validate} error={this.state.userNameValid} onChange={this.changeAllHandler}
                                   placeholder="Your name ex: John Smith"
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField label="Email" variant="outlined" name="userEmail" fullWidth helperText={this.state.userEmailHelperText} value={this.state.userEmail} onBlur={this.validate} error={this.state.userEmailValid} onChange={this.changeAllHandler}
                                   placeholder="Your email ex: abz@abz.com"
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField label="Email format rfc" variant="outlined" name="userEmailRFC" fullWidth helperText={this.state.userEmailRFCHelperText} value={this.state.userEmailRFC} onBlur={this.validate} error={this.state.userEmailRFCValid} onChange={this.changeAllHandler}
                                           placeholder="Your email ex: abz@abz.com"
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField label="Id" variant="outlined" name="userId" fullWidth helperText={this.state.userIdHelperText} value={this.state.userId} onBlur={this.validate} error={this.state.userIdValid} onChange={this.changeAllHandler}
                                           placeholder="Your id ex: id_123_abz"
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField label="Id with mask" variant="outlined" name="userIdMASK" helperText={this.state.userIdMASKHelperText} value={this.state.userIdMASK} onBlur={this.validate} error={this.state.userIdMASKValid} fullWidth onChange={this.changeIdMaskHandler}
                                           inputProps={{
                                               maxLength: 128,
                                           }}
                                           placeholder="Your id ex: 123_123_123"
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <InputMask mask={this.state.mask}  onChange={this.changeAllHandler} onPaste={this.paster}  onBlur={this.validate} helperText={this.state.userPhoneMASKHelperText} error={this.state.userPhoneMASKValid} value={this.state.userPhoneMASK}>
                                    <TextField label="Phone with mask" variant="outlined" name="userPhoneMASK" fullWidth />
                                </InputMask>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField label="Phones with mask" variant="outlined" name="userPhoneExtMASK" helperText={this.state.userPhoneExtMASKHelperText} value={this.state.userPhoneExtMASK} onBlur={this.validate} error={this.state.userPhoneExtMASKValid} fullWidth onChange={this.changePhoneExtMASKHandler}
                                           placeholder="Add different numbers: ex: +44971234567, 49312345567"
                                           inputProps={{
                                               maxLength: 256,
                                           }}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <InputMask mask="9999-9999"  onChange={this.changeAllHandler}  onBlur={this.validate} helperText={this.state.userPinHelperText} error={this.state.userPinValid} value={this.state.userPin} >
                                    <TextField label="Pincode" variant="outlined" name="userPin" fullWidth />
                                </InputMask>
                            </Grid>

                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <TextField label="About you (optional)" variant="outlined" className={style.userAbout} name="userAbout" helperText={this.state.userAboutHelperText} value={this.state.userAbout} onBlur={this.validate} error={this.state.userAboutValid} fullWidth onChange={this.changeAllHandler}
                                           placeholder="Enter a little story about you"
                                           multiline={true}
                                           rows={6}
                                           rowsMax={8}
                                />
                            </Grid>

                        </Grid>
                        <Button variant="contained" onClick={this.validateAll} color="primary" disabled={this.state.buttonDis}>
                            Validate
                        </Button>
                    </form>

                    <Dialog
                        open={this.state.setOpen}
                        onClose={this.handleClickOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Congratulation!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You have successfully completed registration!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClickOpen} color="primary" autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Container>
            </div>
        );
    }
}

export default Form;
