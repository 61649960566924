import React from 'react'
import Container from '@material-ui/core/Container';
import style from './footer.module.scss'


function Footer() {
    return (
            <div className={style.footerWrapper}>
                <Container maxWidth={'md'}>
                    <h4>
                        Volodymyr Pastukh 2020
                    </h4>
                </Container>
            </div>
    );
}

export default Footer;
