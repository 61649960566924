const validator = {
    userName: (userName) => {
        let newUserName = userName.replace(/^\s*/, '').replace(/\s*$/,'').replace(/ . +/g, ' ').replace(/ \./g, '')
        let userNamePattern = /(^[A-z ]{2,}$)/i;

        if(userNamePattern.test(newUserName) && newUserName.length < 128) {
            const valid = {
                isValid: true,
                value: newUserName
            }
            return valid
        } else {
            if(newUserName.length < 1) {
                const valid = {
                    isValid: false,
                    isWhy: "Enter this field",
                    value: newUserName
                }
                return valid
            } else {
                if(newUserName.length < 2) {
                    const valid = {
                        isValid: false,
                        isWhy: "Use min 2 character",
                        value: newUserName
                    }
                    return valid
                }
            }
            if(!userNamePattern.test(newUserName)) {
                const valid = {
                    isValid: false,
                    isWhy: "Use only A-z characters",
                    value: newUserName
                }
                return valid
            } else if(newUserName.length > 128) {
                const valid = {
                    isValid: false,
                    isWhy: `Very long name. Uses ${newUserName.length} of 128`,
                    value: newUserName
                }
                return valid
            }
        }
    },

    userEmail: (userEmail) => {
        let newEmail = userEmail.replace(/^\s*/, '').replace(/\s*$/,'').replace(/ . +/g, '').replace(/ \./g, '').replace(/ +/g, '')
        let emailPattern = /^[a-z0-9.,/']{1,64}@+[a-z0-9.,/']+\.+[a-z0-9.,/']{2,}$/i;
        let dogPattern = /@/
        let letterPattern = /^[0-9a-z@,.\-_']+$/

        if(emailPattern.test(newEmail) && newEmail.length < 256) {
             const valid = {
                isValid: true,
                value: newEmail
             }
            return valid
        } else {
            if(newEmail.length > 254) {
                const valid = {
                    isValid: false,
                    isWhy: `Very long email. Uses ${newEmail.length} of 254`,
                    value: newEmail
                }
                return valid
            }
            if(newEmail.length < 1) {
                const valid = {
                    isValid: false,
                    isWhy: "Enter this field",
                    value: newEmail
                }
                return valid
            }
            if(!letterPattern.test(newEmail)) {
                const valid = {
                    isValid: false,
                    isWhy: "Use only 0-9 a-z , . - _ @",
                    value: newEmail
                }
                return valid
            }
            if(dogPattern.test(newEmail) === false) {
                const valid = {
                    isValid: false,
                    isWhy: "Use @ in email address",
                    value: newEmail
                }
                return valid
            } else {
                const valid = {
                    isValid: false,
                    isWhy: "Local part - 1-64 symbols, domain part(after \".\") - min 2 symbols",
                    value: newEmail
                }
                return valid
            }
        }
    },

    userEmailRFC: (userEmailRFC) => {
        let newEmailRFC = userEmailRFC.replace(/^\s*/, '').replace(/\s*$/,'').replace(/ . +/g, '').replace(/ \./g, '').replace(/ +/g, '')
        let emailPattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}?$/;
        let dogPattern = /@/
        let letterPattern = /^[0-9a-z@,.!#$%&'*+/=?^_`{|}@~\-_']+$/
        let letterPatternS = /@[0-9a-z.]*$/

        if(emailPattern.test(newEmailRFC) && newEmailRFC.length < 255) {
            const valid = {
                isValid: true,
                value: newEmailRFC
            }
            return valid
        } else {
            if(newEmailRFC.length > 254) {
                const valid = {
                    isValid: false,
                    isWhy: `Very long email. Uses ${newEmailRFC.length} of 254`,
                    value: newEmailRFC
                }
                return valid
            }
            if(newEmailRFC.length < 1) {
                const valid = {
                    isValid: false,
                    isWhy: "Enter this field",
                    value: newEmailRFC
                }
                return valid
            }
            if(dogPattern.test(newEmailRFC) === false) {
                const valid = {
                    isValid: false,
                    isWhy: "Use @ in email address",
                    value: newEmailRFC
                }
                return valid
            }
            if(!letterPattern.test(newEmailRFC)) {
                const valid = {
                    isValid: false,
                    isWhy: "Use only 0-9a-z@,.!#$%&'*+/=?^_`{|}@~\\-_ before @ and 0-9 a-z after",
                    value: newEmailRFC
                }
                return valid
            }else {
                if (!letterPatternS.test(newEmailRFC)){
                    const valid = {
                        isValid: false,
                        isWhy: "Use only 0-9 a-z . after @",
                        value: newEmailRFC
                    }
                    return valid
                } else {
                    const valid = {
                        isValid: false,
                        isWhy: "Local part - 1-64 symbols, domain part(after \".\") - min 2 symbols",
                        value: newEmailRFC
                    }
                    return valid
                }
            }
        }
    },

    userId: (userId) => {
        let newUserId = userId.replace(/^\s*/, '').replace(/\s*$/,'').replace(/_*$/,'').replace(/^_+/i,'').replace(/ . +/g, '').replace(/ \./g, '').replace(/ +/g, '')
        let userNamePattern = /^[a-z0-9_]{2,}$/;

        if(userNamePattern.test(newUserId) && newUserId.length < 129 ) {
            const valid = {
                isValid: true,
                value: newUserId
            }
            return valid
        } else {
            if(newUserId.length > 128) {
                const valid = {
                    isValid: false,
                    isWhy: `Very long id. Uses ${newUserId.length} of 128`,
                    value: newUserId
                }
                return valid
            }
            if(newUserId.length < 1) {
                const valid = {
                    isValid: false,
                    isWhy: "Enter this field",
                    value: newUserId
                }
                return valid
            }
            if(newUserId.length === 1) {
                const valid = {
                    isValid: false,
                    isWhy: "Use min 2 character",
                    value: newUserId
                }
                return valid
            } else {
                const valid = {
                    isValid: false,
                    isWhy: "Use only a-z 0-9 _",
                    value: newUserId
                }
                return valid
            }
        }
    },

    userIdMASK: (userIdMASK) => {
        let n = userIdMASK.replace(/_+$/i,'').replace(/^_+/i,'');

        if(n.length < 1){
            const valid = {
                isValid: false,
                isWhy: 'Enter this field',
                value: n
            }
            return valid
        } else {
            if(n.length === 1){
                const valid = {
                    isValid: false,
                    isWhy: 'Use min 2 character',
                    value: n
                }
                return valid
            }else {
                const valid = {
                    isValid: true,
                    isWhy: '',
                    value: n
                }
                return valid
            }
        }
    },

    userPhoneMASK: (userPhoneMASK) => {
        let n = userPhoneMASK.replace(/[()\s-_ ]/g,'')

        if(n.length < 5){
            const valid = {
                isValid: false,
                isWhy: 'Enter this field',
                value: n
            }
            return valid
        } else {
            if(n.length > 4 && n.length < 13){
                const valid = {
                    isValid: false,
                    isWhy: 'Use correct format +38 (0XX) XXX - XX - XX',
                    value: n
                }
                return valid
            }else {
                const valid = {
                    isValid: true,
                    isWhy: '',
                    value: n
                }
                return valid
            }
        }
    },

    userPhoneExtMASK: (userPhoneExtMASK) => {
        let n = userPhoneExtMASK.replace(/^\s*/, '').replace(/\s*$/,'').replace(/ \. +/g, '').replace(/ +/g, ' ').replace(/,+ /g, ', ').replace(/;+ /g, '; ')
        let num = /\d{7,}/g

        if(n.length < 1){
            const valid = {
                isValid: false,
                isWhy: 'Enter this field',
                value: n
            }
            return valid
        } else {
            if(!num.test(n)){
                const valid = {
                    isValid: false,
                    isWhy: 'Use min 7 numbers',
                    value: n
                }
                return valid
            }else {
                if(n.length > 256){
                    const valid = {
                        isValid: false,
                        isWhy: `Very long. Uses ${n.length} of 256`,
                        value: n
                    }
                    return valid
                } else {
                    const valid = {
                        isValid: true,
                        isWhy: '',
                        value: n
                    }
                    return valid
                }
            }
        }
    },

    userPin: (userPin) => {
        let n = userPin.replace(/[-_]/g,'')

        if(n.length < 1){
            const valid = {
                isValid: false,
                isWhy: 'Enter this field',
                value: n
            }
            return valid
        } else {
            if(n.length > 0 && n.length < 8){
                const valid = {
                    isValid: false,
                    isWhy: 'Use correct format xxxx-xxxx',
                    value: n
                }
                return valid
            }else {
                const valid = {
                    isValid: true,
                    isWhy: '',
                    value: n
                }
                return valid
            }
        }
    },

    userAbout: (userAbout) => {
        let n = userAbout.replace(/^ */gm,'').replace(/\n{2,}/g,'\n\n').replace(/ \. +/g, '').replace(/ \./g, '').replace(/\s*$/,'').replace(/^\s*/,'').replace(/ +/g, ' ')

            if(n.length > 500 ){
                const valid = {
                    isValid: false,
                    isWhy: `Very long story. Uses ${n.length} of 500`,
                    value: n
                }
                return valid
            }else {
                const valid = {
                    isValid: true,
                    isWhy: 'Use 500 character',
                    value: n
                }
                return valid
            }

    },
}





export default validator
