import React from 'react';

import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Form from './components/Form/Form'

import './App.css';

function App() {
  return (
    <div className="App">
        <Header/>
        <main className="content">
            <Form/>
        </main>
        <Footer/>
    </div>
  );
}

export default App;
