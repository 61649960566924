import React from 'react'
import Container from '@material-ui/core/Container';

import style from './header.module.scss'

function Header() {
    return (
        <header>
            <div className={style.headerWrapper}>
                <Container maxWidth={'md'}>
                    <h2>
                        Validate form
                    </h2>
                </Container>
            </div>
        </header>
    );
}

export default Header;
